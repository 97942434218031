.post {
    --space: 0.5rem;

    padding: 1rem;
    background-color: white;
    margin-bottom: var(--space);

    &:last-child {
        --space: 0;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .header {
        display: flex;
        flex-direction: row;
        padding: 0.5rem;

        .tags {
            flex-grow: 1;

            .tag {
                --height: 1rem;
                font-size: 0.75rem;
                padding: 0 0.4rem;
                margin-right: 1rem;
            }
        }

        .action {
            align-self: flex-end;
            font-size: 0.75rem;
            cursor: pointer;
            border: none;
            background: none;
            color: black;

            svg {
                margin: 0 0.25rem -0.125rem;
                height: 1.5em;
            }
        }
    }

    .content {
        padding: 1rem;
    }

    .footer {
        display: flex;
        flex-direction: row;
        padding: 0.75rem;

        .date {
            align-self: flex-start;
            flex-grow: 1;
            font-size: 0.75rem;
        }
    }
}
