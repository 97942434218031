.backdrop {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fffc;
    z-index: 1;
}

.container {
    position: absolute;
    box-sizing: border-box;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    max-height: 100%;
    z-index: 2;
}

.window {
    background-color: white;
    border: #ccc 1px solid;
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
}

.header {
    display: block;
    margin: 1rem 0;
}

.action {
    float: right;
}

.content {
    flex: 1 1 0;
    overflow: auto;
}

.actions {
    flex-: 1 0 0;
}