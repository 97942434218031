.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
}

.body {
    position: relative;
    flex: 1 1 0;
    overflow: hidden;
}
