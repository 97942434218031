.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ddd;

    .refresh {
        float: right;
        cursor: pointer;
        border: none;
        background: none;
        color: black;

        svg {
            height: 1rem;
        }
    }

    .list {
        overflow: auto;
        flex-grow: 1;
    }
}